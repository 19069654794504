// Component states
import states from './align-horizontal-content-modifier.state.js'
import MdiFormatAlignLeft from 'vue-material-design-icons/FormatAlignLeft'
import MdiFormatAlignRight from 'vue-material-design-icons/FormatAlignRight'
import MdiFormatAlignCenter from 'vue-material-design-icons/FormatAlignCenter'
import MdiFormatAlignJustify from 'vue-material-design-icons/FormatAlignJustify.vue'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Align-horizontal-content-modifier'

// Properties
const props = {
  mjml: Object,
  customKey: String,
  isText: Boolean
}

// Subcomponents
const components = {
  MdiFormatAlignLeft,
  MdiFormatAlignRight,
  MdiFormatAlignCenter,
  MdiFormatAlignJustify
}

// Data
const data = function () {
  return {
    states
  }
}

// Methods
const methods = {

  /**
   * Callback called when one of the button from the alignement control is pressed
   * @param {String} alignement
   */
  onAlignButtonClicked (alignement) {
    this.mjml.attributes[this.customKey || 'align'] = alignement
  },

  /**
   * Check if the current align value is active
   * @param  {Object}  option (current option)
   *
   * @return {Boolean}
   */
  isActive (option) {
    return option.value === this.mjml.attributes[this.customKey || 'align']
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  components
}
