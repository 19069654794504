// Component states
import states from './actions-image-modifier.state.js'

// Components
import ImagePathModifier from '../image-path-modifier/Image-path-modifier.vue'
import AlignHorizontalContentModifier from '../align-horizontal-content-modifier/Align-horizontal-content-modifier.vue'
import MdiImageMultiple from 'vue-material-design-icons/ImageMultiple.vue'
import MdiAccount from 'vue-material-design-icons/Account.vue'

// Services
import { eventEmit } from '@/services/utils/utils.service'
import { fileManager, lockEditPanel } from '@/services/states/states.service'

// Library
import {
  get as _get,
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Actions-mj-image'

// Properties
const props = {
  conf: Object,
  mjml: Object
}

// Subcomponents
const components = {
  MdiImageMultiple,
  MdiAccount,
  ImagePathModifier,
  AlignHorizontalContentModifier
}

// Data
const data = function () {
  return {
    states: _cloneDeep(states),
    focused: false,
    isUrlValid: true
  }
}

const computed = {
  // Func@fileManagerActive
  /**
   * File manager status
   * @return {Boolean}
   */
  fileManagerActive () {
    return fileManager().get()
  },
  // Func@fileManagerActive

  href: {
    // getter
    get () {
      return this.states.href.replace(/&quot;/g, '"')
    },
    // setter
    set (newValue) {
      const val = newValue.replace(/"/g, '&quot;') // Fix MJML issue with with `"` in html attr
      this.$set(this.states, 'href', val)
      if (this.isUrlValid || !_get(this.conf, 'alertType')) {
        this.$set(this.mjml.attributes, 'href', this.states.href)
      }
    }
  },

  
}

// Methods
const methods = {

  /**
   * Check if url is valid
   * @returns {Boolean}
   */
  validateUrl () {
    if (this.states.href.length > 0) {
      let rgx = '';
      if (this.states.href.includes('http')) {
        const link = /(http|https)?:\/\/(?:www\.|(?!www\.))([a-zA-Z0-9-[\]=#_/+%!]+)?([-]{0,1}[a-zA-Z0-9-[\]=#_/+%!]){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,}([a-zA-Z0-9-[\]=#_/+%!]+)?([-]{0,1}[a-zA-Z0-9-[\]=#_/+%!])?(?:\.(([a-zA-Z0-9-[\]=#_/+%!:,']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})){1,}(?:\?(([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}){0,}(?:&(([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,}([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}([-]{0,1}[a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}){0,}/gm
         rgx = new RegExp(link)
         this.isUrlValid =  this.states.href.match(rgx) && this.states.href.match(rgx)[0].length === this.states.href.length
      } else if (this.states.href.includes('mailto')) {
        const mail = /(mailto)?:(([a-zA-Z0-9#_.+-]|((?:(?:{{[^}{]{1,}}}{1,2})|(?!(?:{{[^}{]{1,}}}))))|(?:(?:%%[^%]{1,}%%{1,2})|(?!(?:%%[^%]{1,}%%)))|(?:(?:<%[^%<>]{1,}%>)|(?!(?:<%[^%.<]{1,}%>))))+@[a-zA-Z0-9#_.+-]+\.[a-zA-Z0-9_.+&=?]{1,})?|((?:{{[^}{]{1,}}})|([a-zA-Z0-9#=&-_\s%]+))|((?:%%[^%]{1,}%%)|([a-zA-Z0-9#=&-_\s%]+))|((?:<%[a-zA-Z0-9#=&-_\s%]+%>)|([a-zA-Z0-9#=&-_]+))/gm
        rgx = new RegExp(mail)
        this.isUrlValid = this.states.href.length > 7 && this.states.href.match(rgx) && this.states.href.match(rgx).join('').length === this.states.href.length
      } else if (this.states.href.includes('tel')) {
        const tel = /(tel)?:([0-9+#]+)?(?:(?:{{[^}{]{1,}}})|(?!(?:{{[^}{]{1,}}})))?(?:(?:%%[^%]{1,}%%{1,2})|(?!(?:%%[^%]{1,}%%)))?(?:(?:<%[^%<>]{1,}%>)|(?!(?:<%[^%.<]{1,}%>)))?([0-9+#]+)?|((?:{{[^}{]{1,}}})|([a-zA-Z0-9#=&-_\s%]+))|((?:%%[^%]{1,}%%)|([a-zA-Z0-9#=&-_\s%]+))|((?:<%[a-zA-Z0-9#=&-_\s%]+%>)|([a-zA-Z0-9#=&-_]+))/gm
        rgx = new RegExp(tel)
        this.isUrlValid = this.states.href.length > 4 && this.states.href.match(rgx) &&  this.states.href.match(rgx).join('').length === this.states.href.length
      } else { // perso only
        const perso = /(?:{{[^}{]{1,}}}){0,}([a-zA-Z0-9-[\]=#_\\/+%!?&.]){0,}(?:{{[^}{]{1,}}}){0,}|(?:%%[^%]{0,}%%){0,}[a-zA-Z0-9-[\]=#_\\/+%!?&.]{0,}(?:%%[^%]{1,}%%){0,}|(?:<%[^%<>]{1,}%>){0,}[a-zA-Z0-9-[\]=#_\\/+%!?&.]{0,}(?:<%[^%<>]{1,}%>){0,}|(?:#[^#\s]{1,}){0,}[a-zA-Z0-9-[\]=#_\\/+%!?&.]{0,}(?:#[^#]{1,}#){0,}/gm
        rgx = new RegExp(perso)
        this.isUrlValid = this.states.href.match(rgx) && this.states.href.match(rgx).join('').length === this.states.href.length
      }
    } else {
      this.isUrlValid = true
    }
    
  },
  // Func@checkError
  /**
   * Check if corresponding error is detected
   */
  checkError () {
    const alertInConfig = _get(this.conf, 'alertType') === 'wrong_url'
    this.states.hasError = alertInConfig && !this.isUrlValid && this.states.href.length
    this.states.hasError ? lockEditPanel(true, 'wrong_url') : lockEditPanel(false)
  },
  // Func@checkError

  /**
   * Function to set url to current componenent from event
   * Sets the src attribute of the image with the new path.
   * @param  {data} object   (object sent from event)
   */
  setFileUrlFromEvent (event) {
    if (typeof event.data !== 'object') { return }
    const call = event.data.call
    const value = event.data.value
    if (call === 'file-manager:file') {
      if (value.url && value.fallback === 'actions') {
        this.$set(this, 'href', value.url)
      }
    }
    if (call == 'personalization-fields-manager:text') {
      if (!value.text || value.fallback !== 'actions')
        return
      var cursorPosition = document.querySelector("#action-image-modifier-input").selectionStart

      if (this.href.length === 0)
        this.href = value.text
      else if (cursorPosition > 0)
        this.href = (
          this.href.slice(0, cursorPosition)
          + value.text
          + this.href.slice(cursorPosition)
        )
      else
        this.href += value.text
    }
  },

  /**
   * Function to handle opening of file manager
   */
  openFileManager () {
    eventEmit('open-file-manager', { fallback: 'actions' })
  },

  /**
  * Function to handle opening of personalization fields manager
  */
  openPersonalizationFieldsManager: function () {
    eventEmit('open-personalization-fields-manager', { fallback: 'actions' })
  },
}

// Vue@watchTemplate (disabled for now)
const watch = {
  'states.href': {
    handler () {
      this.checkError()
    },
    deep: true
  }
}
// Vue@watchTemplate

// Func@addWindowListener on create
/**
 * Add window resize event listener and define save html
 * request to be able to flush it on destroy
 */
function created () {
  this.states.href = this.mjml.attributes.href
  window.addEventListener('message', this.setFileUrlFromEvent)
}
// Func@addWindowListener

// Func@removeWindowListener on destroy
/**
 * Remove window resize event listener
 */
function destroyed () {
  window.removeEventListener('message', this.setFileUrlFromEvent)
}
// Func@removeWindowListener

// Vue component syntax
export default {
  name,
  data,
  props,
  components,
  computed,
  watch,
  methods,
  created,
  destroyed
}
