export default {
  options: [
    {
      value: 'left',
      icon: 'mdi-format-align-left'
    },
    {
      value: 'center',
      icon: 'mdi-format-align-center'
    },
    {
      value: 'right',
      icon: 'mdi-format-align-right'
    },
    {
      value: 'justify',
      icon: 'mdi-format-align-justify'
    }
  ]
}
