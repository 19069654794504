import { render, staticRenderFns } from "./align-horizontal-content-modifier.html?vue&type=template&id=669837bc&scoped=true&external"
import script from "./align-horizontal-content-modifier.script.js?vue&type=script&lang=js&external"
export * from "./align-horizontal-content-modifier.script.js?vue&type=script&lang=js&external"
import style0 from "./align-horizontal-content-modifier.scss?vue&type=style&index=0&id=669837bc&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "669837bc",
  null
  
)

export default component.exports