export default {
  action: {},
  hasError: false,
  href: '',
  items: [
    {
      value: 'open',
      label: 't_open-landing-page-action_'
    }
  ]
}
